<template>
    <div class="bg-[#B2CEE9]">
        <div class="container py-16 md:py-20">
            <div class="flex mb-5">
                <div>
                    <h3 class="font-display text-primary text-2xl md:text-3xl lg:text-[40px] font-semibold mb-4">Cosa contiene la happy box?</h3>
                    <p class="text-gray-700 font-thin text-sm md:text-base">La confezione è ricca di prodotti e servizi per le mamme e i papà in
                        dolce attesa.</p>
                </div>
            </div>

            <!-- mobile -->
            <div class="md:hidden -mr-5">
                <Swiper
                    :modules="[SwiperAutoplay, SwiperPagination]"
                    :slides-per-view="2.3"
                    :spaceBetween="10"
                    :pagination="{
                        el: '.swiper-pagination'
                    }"
                    :autoplay="{
                    delay: 8000,
                    disableOnInteraction: true,
                    }"
                >
                    <SwiperSlide v-for="content in happyBoxContents">
                        <HomeHappyBoxContentsItem :item="content" />
                    </SwiperSlide>
                    <div class="swiper-pagination mt-6 w-full relative"></div>
                </Swiper>
            </div>

            <!-- desktop -->
            <div class="hidden md:flex flex-wrap -mx-2">
                <div v-for="content in happyBoxContents" class="md:w-1/3 lg:w-1/6 flex px-2 mb-4">
                    <div class="w-full flex flex-col justify-center items-center rounded-lg bg-white px-5 py-7">
                        <img :src="content.image" :alt="content.title" class="w-20" />
                        <p class="text-gray-800 font-display leading-tight mt-8 text-center">{{ content.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup type="tx">
    import { ref } from 'vue';

    const happyBoxContents = [
        {
            image: 'icons/pampers.svg',
            title: "Pannolini",
        },
        {
            image: "icons/papers.svg",
            title: "Salviettine",
        },
        {
            image: "icons/bathroom.svg",
            title: "Prodotti per il bagnetto",
        },
        {
            image: "icons/socks.svg",
            title: "Calzine per neonato",
        },
        {
            image: "icons/offers.svg",
            title: "Offerte speciali*",
        },
        {
            image: "icons/plus.svg",
            title: "E tanto altro…",
        },
    ];
</script>
